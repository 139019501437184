function autoForm(value) {
    if(value != null && value.indexOf('<form') >= 0){
      var s = value.indexOf('<form');
      var e = value.indexOf('</form>') + '</form>'.length;
      return value.substring(s, e);
    }else{
      return "";
    }
}

export {autoForm};
