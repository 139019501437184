<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.dragonphoenix.header') }}</p>
            <p>{{ $t('deposit.method.desc', { method: $t('deposit.dragonphoenix.header') }) }}</p>
            <ul>
              <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
              <li>{{ $t('deposit.method.inst10', { number: '2' }) }}</li>
              <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
            </ul>
            <p class="title">{{ $t('deposit.method.form.header', { method: $t('deposit.dragonphoenix.header') }) }}</p>
          </div>
          <div class="form_main">
            <el-form label-position="top" :model="dfForm" ref="dfForm" status-icon :rules="dfRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, dfForm.amount)" prop="amount">
                      <numeric-input v-model="dfForm.amount" :currency="accountCurrency" :precision="2"></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li class="data">
                    <p>
                      {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'KRM' }) }}
                      <span> {{ rate }}</span>
                    </p>
                    <p>
                      KRM: <span>{{ getRate(rateChange) }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('register.personalDetails.page1.lastName')" prop="lastName">
                      <el-input v-model="dfForm.lastName" data-testid="lastName"></el-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="$t('register.personalDetails.page1.firstName')" prop="firstName">
                      <el-input v-model="dfForm.firstName" data-testid="firstName"></el-input>
                    </el-form-item>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                      <el-input v-model="dfForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div v-html="bankHTML"></div>
      </div>
      <div class="form-right">
        <div class="logo circle bank"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import { autoForm } from '@/util/getAutoForm';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import mixin from '@/mixins/page/deposit';
import { apiQueryRate_USD_KRW, apiDragonPhoenix_payment } from '@/resource';

export default {
  name: 'dragonPhoenixDeposit',
  components: { NumericInput, AccountNumber, DefaultTemplate },
  mixins: [mixin],
  data() {
    const individualUserName = this.$store.state.common.individualUserName;
    const firstName = individualUserName.split(' ')[0];
    const lastName = individualUserName.split(firstName)[1];

    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      dfForm: {
        accountNumber: '',
        amount: '',
        firstName: firstName,
        lastName: lastName,
        notes: ''
      },
      dfRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        firstName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.firstNameReq')
          }
        ],
        lastName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.lastNameReq')
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'USC'],
      bankHTML: '',
      rate: 0.0
    };
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.dfForm.amount, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.dfForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiDragonPhoenix_payment(
        {
          mt4Account: this.dfForm.accountNumber,
          operateAmount: this.dfForm.amount,
          applicationNotes: this.dfForm.notes,
          rate: this.rate,
          paymentMethod: 'dragon phoenix',
          firstName: this.dfForm.firstName,
          lastName: this.dfForm.lastName
        },
        this.token
      );
    },
    success(msg) {
      this.bankHTML = autoForm(msg);
      this.$nextTick(function() {
        if (document.getElementById('redirForm')) {
          document.getElementById('redirForm').submit();
        } else {
          this.errorMessage(this.$t('deposit.default.failed'));
        }
      });
    },
    submitForm() {
      this.$refs['dfForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    },
    queryRate() {
      apiQueryRate_USD_KRW()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'KRM' })
          );
        });
    }
  },
  mounted() {
    this.queryRate();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
